.wrapper {
    position: relative;
    box-sizing: border-box;
}


.inner {
    position: relative;
    padding-bottom: 20px;

    @media (max-width: $mantine-breakpoint-sm) {
        padding-bottom: 20px;
        padding-top: 80px;
    }
}

.title {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    font-size: 62px;
    font-weight: 900;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 1.2;
        margin-bottom: 10px;
    }
}

.description {
    margin-top: var(--mantine-spacing-xl);
    font-size: 24px;
    color: #454A4F;

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: 18px;
    }
}

.controls {
    margin-top: calc(var(--mantine-spacing-xl) * 2);

    @media (max-width: $mantine-breakpoint-sm) {
        margin-top: var(--mantine-spacing-xl);
    }
}

.control {
    height: 54px;
    padding-left: 38px;
    padding-right: 38px;

    @media (max-width: $mantine-breakpoint-sm) {
        height: 54px;
        padding-left: 18px;
        padding-right: 18px;
        flex: 1;
    }
}


/* New CSS for the image */

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: auto;
    padding-top: 30px;
}

.content {
    flex: 1 1 70%;
}

.imageContainer {
    flex: 1 1 30%;
    padding-bottom: 120px;

    @media (max-width: 992px) {
        display: none;
    }
}

/* Make sure the image isn't too large */
.image {
    max-width: 100%;
    height: auto;
}
