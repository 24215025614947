.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #282c34;
    color: white;
}

.mainApp {
    height: 100vh;
    background-image: linear-gradient(70deg, #baeeff, var(--mantine-color-violet-1), #baeeff, var(--mantine-color-violet-2));
    color: #282c34;
    overflow: hidden;
}

html, body {
    overscroll-behavior: none;
}