.title {
  font-weight: 900;
  padding-top: 35px;
  padding-bottom: 20px;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 24px;
    text-align: left;
  }
}

.description {

  margin: auto;
  max-width: 1200px;
  color: #454A4F;

  @media (max-width: $mantine-breakpoint-sm) {
    text-align: left;
  }

  &::after {
    content: '';
    display: block;
    background-color: var(--mantine-color-blue-filled);
    width: 45px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $mantine-breakpoint-sm) {
    text-align: left;
  }
}

.card {
  border: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  align-items: center;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

    .cardTitle {
      color: var(--mantine-color-blue-filled);
    }
  }

  @media (max-width: $mantine-breakpoint-sm) {
    margin: 20px 0;
  }

}

.cardTitle {
  &::after {
    content: '';
    display: block;
    background-color: var(--mantine-color-blue-filled);
    width: 45px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $mantine-breakpoint-sm) {
      margin-left: 0;
    }

  }

  @media (max-width: $mantine-breakpoint-sm) {
    text-align: left;
  }
}

.item {
  border-radius: var(--mantine-radius-md);
  margin-bottom: var(--mantine-spacing-lg);
  border: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.note {
  font-size: 14px;
  color: var(--mantine-color-gray-6);
  margin-top: var(--mantine-spacing-xs);
  font-style: italic;
  padding-top: 2px;

  @media (max-width: $mantine-breakpoint-sm) {
    text-align: left;
  }
}