/* SearchDrawer.module.css */

.drawerContent {
    display: flex;
    flex-direction: column;
    max-width: 700px; /* Adjust based on your preference */
    margin: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    height: calc(100vh - 40px); /* Set height to less than viewport height */
  }
  
.formGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
  
.applyButton {
    margin-top: 20px;
    grid-column: span 2;
  }
  
.closeButton {
    color: #fff;
    
}

.seenJobsSwitch {
    margin-top: 10px;
}

.closeButton:hover {
    color: #696969;
}

.remoteControl {
    grid-column: span 2;
}

@media (max-width: 768px) {
    .drawerContent {
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        height: calc(100vh - 10px);
    }
    
    .formGroup {
        grid-template-columns: 1fr;
    }

    .remoteControl {
        grid-column: span 1;
    }

    .applyButton {
        margin-top: 10px;
        grid-column: span 1;
    }
}


