.card {
    max-width: 675px;
    margin: auto;
    /* We want height equal to 100vh minus the header height */
    height: calc(85vh - 100px);
    border-radius: 8px;
    margin-bottom: 20px;

    /* we want to make text not selectable */
    user-select: none;


    /* We want to ensure a higher height for the card if on phone */
    @media (max-width: 767px) {
        height: calc(100vh - 100px);
    }

}

.title {
    font-size: 1.25rem;
    font-weight: 500;
}

.level,
.category {
    font-weight: 500;
    color: var(--mantine-color-gray-7);
}

.body {
    margin-top: 0.5rem;
}

.scrollArea {
    margin-top: 0.5rem;
    color: var(--mantine-color-gray-7);
    font-size: 0.875rem;
    /* We want to ensure that scroll-area use all the available space */
    flex-grow: 1;
}

.actionButton {
    /* Additional styling for the action button if necessary */
}

.applyButton {
    /* We want to ensure a minimum height for the button */
    min-height: 40px;
}


.actions {
    display: flex;
    justify-content: center;
}

.jobLink {
    /* We want no style at all for the link */
    text-decoration: none;
    color: inherit;
}