/* ShoppingBagDrawer.module.css */

.drawerContent {
    display: flex;
    flex-direction: column;
}

.drawerContentInner {
    background-image: linear-gradient(70deg, #baeeff, var(--mantine-color-violet-1), #baeeff, var(--mantine-color-violet-2));
}

.drawerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: whitesmoke;
}

.jobItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 11px;
    border-radius: 5px;
    /*background-color: #f3f4f6; will use this color when somone applied for job*/
    background-color: white;
    /* Light grey background */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Slightly deeper shadow for floating effect */
    /* Soft shadow for depth */
    border: 1px solid #e1e4e8; /* Subtle border color */
}

.badge {
    text-overflow: ellipsis;
}

.jobInfo {
    flex-grow: 1;
    margin-right: 15px;
}

.jobTitle {
    font-weight: 500;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px; /* Space between title and badges */
}

.jobCompany,
.jobLocation {
    font-size: 0.9rem;
    color: #555;
    /* Darker text for contrast */
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.applyAllButton,
.applyButton,
.removeButton,
.emptyBagButton {
    margin-left: 0px;
    font-size: 0.875rem; /* Uniform text size for buttons */
    padding: 5px 10px; /* Padding for touch targets */
    border-radius: 6px; /* Rounded corners for buttons */
}

  
  .removeButton {
    background-color: #ff3b30; /* AscendKit theme color for remove button */
    color: white;
  }
  
  .removeButton:hover {
    background-color: #e63629; /* Slightly darker on hover */
  }
  
  .emptyBagButton {
    background-color:  #ff3b30; /* AscendKit theme color for empty bag button */
    color: white;
    margin-top: 20px; /* Spacing above the primary action button */
  }

  .applyAllButton {
    margin-top: 20px;
  }
  
  .emptyBagButton:hover {
    background-color: #e63629; /* Slightly darker on hover */
  }
  
  .icon {
    margin-right: 4px; /* Space between icon and text */
  }
  
  /* Responsive design tweaks can be added with media queries if necessary */
  
  .drawerTitle {
    font-size: 1.25rem; /* Larger font size for the drawer title */
    color: #000000; /* AscendKit's theme color */
    font-weight: 600; /* Semi-bold for a strong presence */
    margin-bottom: 12px; /* Spacing under the title */
}

.jobTitle {
    /* Adjust font properties if needed */
    font-size: 1rem; /* Slightly larger than the body text for emphasis */
    margin-bottom: 10px; /* Space between title and badges */
}

.drawerParagraph {
    color: #333; /* Darker text color for readability */
    margin-bottom: 15px; /* Space between paragraphs */
}

.emptyBagMessage {
    color: #333; /* Darker text color for contrast */
    margin-bottom: 15px; /* Space between message and buttons */
    font-weight: 700; /* Medium weight for emphasis */
}
/* Update badge styles for better visibility */


/* ShoppingBagDrawer.module.css */


/* Additional styles for applied jobs section */

.appliedJobItem {
  color: #555;
  margin-bottom: 5px; /* Space between job entries */
}

.appliedJobItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 11px;
  border-radius: 5px;
  background-color: rgb(180, 181, 181); /* Light grey background */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border: 1px solid rgb(178, 177, 177);
}
