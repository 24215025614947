/* JobCard.module.css */
.slideUpWithOpacity {
    animation: slideUpWithOpacity 0.5s forwards;
}

.slideUp {
    animation: slideUp 0.5s forwards;
}

@keyframes slideUpWithOpacity {
    from {
        transform: translateY(0%);
        opacity: 1;
    }

    to {
        transform: translateY(calc(-100% - 20px));
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(calc(-100% - 20px));
        /* 20px is the margin-bottom of the card */
    }
}

.bounce {
    animation: bounce 0.5s forwards;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-10%);
    }
}

/* JobCard.module.css */
.bounceBack {
    animation: bounceBack 0.5s forwards;
}

@keyframes bounceBack {
    0%, 100% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(10%); /* Notice the direction is positive, moving downwards */
    }
}


/* JobCard.module.css additions */

.slideDownWithOpacity {
    animation: slideDownWithOpacity 0.5s forwards;
  }
  
  .slideDown {
    animation: slideDown 0.5s forwards;
  }
  
  @keyframes slideDownWithOpacity {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
  
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
  
    to {
      transform: translateY(0%);
    }
  }
  
  .reverseBounce {
    animation: reverseBounce 0.5s forwards;
  }
  
  @keyframes reverseBounce {
    0%, 100% {
      transform: translateY(0%);
    }
    
    50% {
      transform: translateY(10%);
    }
  }
  