.header {
  position: fixed;
  width: 100%;
  height: 60px;
  background-image: linear-gradient(70deg, #baeeff, var(--mantine-color-violet-1), #baeeff, var(--mantine-color-violet-2));
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  z-index: 1000;
}

.inner {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 700;
  font-size: var(--mantine-font-size-sm);

  @media (max-width: $mantine-breakpoint-sm) {
    height: 42px;
    width: 100%;
  }

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.subLink {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  }
}

.dropdownFooter {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}


.logo {
  display: flex;
  align-items: center;
  height: 90%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 700;
  font-size: var(--mantine-font-size-lg);
}