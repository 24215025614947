.header {
    height: 60px;
    margin-bottom: 20px;
    background-color: var(--mantine-color-body);
    width: 100%;
    background-image: linear-gradient(70deg, #baeeff, var(--mantine-color-violet-1), #baeeff, var(--mantine-color-violet-2));
    z-index: 1000;
    display: flex;
    align-items: center;
    position: relative;
}

.logo {
    display: flex;
    align-items: center;
    height: 90%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);

    @media (max-width: 992px) {
        height: 85%;
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 767px) {
        display: none;
    }
}


.iconLogo {
    display: flex;
    align-items: center;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-xl);
    color: var(--mantine-color-blue-6);

    @media (min-width: 768px) {
        display: none;
    }
}

.searchButton {
    /* Should take a certain width if possible */
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.centerGroup {
    flex-grow: 1;
    justify-content: center;
    margin-left: calc(var(--mantine-spacing-md));
    margin-right: calc(var(--mantine-spacing-md) * 6.5);

    @media (max-width: 992px) {
        margin-left: 0;
        margin-right: 0;
    }
}