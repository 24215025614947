.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #282c34;
    color: white;
}


.mainApp {
    height: 100vh;
    background-image: linear-gradient(70deg, #baeeff, var(--mantine-color-violet-1), #baeeff, var(--mantine-color-violet-2));
    color: #282c34;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px;
    max-width: 95%;
    margin: 0 auto;
}

.tableTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.tableSubtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    background-color: white;
}

table th, table td {
    text-align: left;
    padding: 10px 15px;
}

th {
    background-color: #f8f8f8;
    color: #333;
    font-weight: 600;
}

tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.ActionIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s, transform 0.3s;
}

.ActionIcon:hover {
    background-color: #e3e3e3;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .table {
        overflow-x: auto;
    }

    table {
        width: 100%;
    }

    .ActionIcon {
        width: 30px;
        height: 30px;
    }
}


/* General background */
.mainApp {
    background-image: linear-gradient(120deg, #e3f2fd, #e3f2fd, #e3f2fd, #bbdefb); /* Gradient background for the app */
    color: #424242; /* Changing the main text color for better contrast */
}

/* Container */
.container {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
    border-radius: 8px; /* Rounded corners for the container */
    overflow: hidden; /* Ensures the border-radius clips the contents */
    background: #ffffff; /* Setting a solid white background for the table */
}

/* Table header */
table th {
    background-color: #f5f5f5; /* Light grey background for headers */
}

/* Table body */
table tbody tr:nth-of-type(odd) {
    background-color: #fafafa; /* Zebra striping for rows */
}

/* Table cells */
table th, table td {
    padding: 12px 15px; /* More padding for cells */
}

/* Badges */
.Badge {
    border-radius: 12px; /* Rounded corners for badges */
    padding: 5px 10px; /* Padding inside badges */
    font-size: 0.85em; /* Smaller font size for badge text */
}

/* Colors for verified and validated badges */
.badge-verified {
    background-color: #c8e6c9; /* Light green for verified */
    color: #1b5e20; /* Dark green text color for better readability */
}

.badge-not-verified {
    background-color: #ffcdd2; /* Light red for not verified */
    color: #b71c1c; /* Dark red text color for better readability */
}

.badge-validated {
    background-color: #bbdefb; /* Light blue for validated */
    color: #0d47a1; /* Dark blue text color for better readability */
}

.badge-not-validated {
    background-color: #ffe0b2; /* Light orange for not validated */
    color: #e65100; /* Dark orange text color for better readability */
}

/* Account type badge colors */
.badge-admin {
    background-color: #b39ddb; /* Light purple for admin */
    color: #4a148c; /* Dark purple text color for readability */
}

.badge-default {
    background-color: #e0e0e0; /* Grey for default */
    color: #424242; /* Dark grey text for readability */
}



.ActionIcon:hover {
    background-color: #e0f7fa; /* Light hover background */
    transform: scale(1.05); /* Slight increase in size on hover */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .table {
        overflow-x: auto;
    }
}


.approveModal .mantine-Modal-modal {
  padding: 0; /* Remove padding from the modal to control it within the content */
  width: auto; /* Adjust width as needed */
  max-width: 400px; /* Set a max-width for larger screens */
}

/* This styles the header of the modal */
.approveModal .mantine-Modal-title {
  font-weight: bold;
  text-align: center;
  background-color: #f0f0f0; /* Light grey background for the title area */
  padding: 20px;
  border-bottom: 1px solid #e0e0e0; /* Add a border to separate from the content */
}

/* This styles the close button of the modal */
.approveModal .mantine-Modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Styles for buttons */
.approveModal .mantine-Button-root {
  flex: 1; /* Make buttons take equal space */
  margin: 0 10px; /* Add some margin between buttons */
}

/* If you have the Mantine Button component inside, you might want to add some custom styles */
.approveModal button {
  margin-top: 20px; /* Spacing above buttons */
  margin-right: 10px; /* Spacing between buttons */
}

/* Additional styling can be applied as per your design requirements */
