.wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    /* Align vertically in the center */
    justify-content: center;
    /* Align horizontally in the center */
    padding: 0 15px;
    /* Add some padding on smaller screens */
}

.card {
    width: 100%;
    /* Take the full width of the container */
    max-width: 500px;
    /* Suitable width for the card */
    margin-top: 2vh;
    /* Adjust this to move the card up */
    padding: 20px;
    margin-bottom: 50px;
}

.title {
    margin-bottom: 30px;
    /* Add some space below the title */
    text-align: center;
    /* Center the title if you like */
}

.text {
    margin-top: -20px;
    /* Add some space below the title */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 480px) {
    .container {
        padding: 0 10px;
        /* Smaller padding on very small screens */
    }

    .card {
        margin-top: 20px;
        /* Less vertical space on very small screens */
        padding: 15px;
        /* Smaller padding within the card */
    }

    .stepper {
        padding-top: 15px;
        /* Less space above the stepper */
    }
}