.footer {
    position: sticky;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(70deg, #baeeff, var(--mantine-color-violet-2), #baeeff, var(--mantine-color-violet-3));
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    z-index: 1000;
  }
  
  
  .logo {
    display: flex;
    align-items: center;
    height: 90%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
  }