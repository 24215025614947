.wrapper {
    padding-top: calc(var(--mantine-spacing-xl) * 2.5);
    padding-bottom: calc(var(--mantine-spacing-xl) * 4);
}

.title {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    font-weight: 900;
    margin-bottom: var(--mantine-spacing-md);
    text-align: center;
    padding-bottom: 20px;

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: 28px;
        text-align: left;
    }
}

.description {
    text-align: center;
    color: #454A4F;

    @media (max-width: $mantine-breakpoint-sm) {
        text-align: left;
    }
}