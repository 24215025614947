.wrapper {
    margin: auto;
    padding-top: 80px;
    text-align: center;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: auto;
}

.inner {
    position: relative;
    padding-bottom: 20px;

    @media (max-width: $mantine-breakpoint-sm) {
        padding-bottom: 20px;
        padding-top: 80px;
    }
}

.title {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    font-weight: 900;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: 28px;
        line-height: 1.2;
        text-align: left;
    }
}

.description {
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #454A4F;

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: 18px;
        text-align: left;
    }
}

.timeline {
    margin-top: var(--mantine-spacing-xl);
    margin-left: 8%;


    @media (max-width: $mantine-breakpoint-sm) {
        font-size: 18px;
    }
}